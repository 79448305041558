import { defineStore } from 'pinia';

import { i18n } from '@/assets/translations';

interface LocaleState {
  value: string;
}

export const useLocaleStore = defineStore('locale', {
  state: (): LocaleState => {
    return {
      value: i18n.global.locale.value
    };
  },
  actions: {
    setLocale(locale: string) {
      this.value = locale;
      i18n.global.locale.value = locale;
    }
  },
  persist: true
});
