<script setup lang="ts">
import { computed } from 'vue';
import { kebabCase } from 'lodash';

const props = defineProps<{
  iconName: string;
  iconSize?: string;
}>();

const url = computed(
  () => new URL(`/src/assets/icons/icon-${kebabCase(props.iconName)}.svg`, import.meta.url).href
);

const sizeClasses = computed((): string => {
  switch (props.iconSize) {
    case 'sm':
      return 'w-4 h-4';
    case 'lg':
      return 'w-8 h-8';
    case 'xl':
      return 'w-9 h-9';
    default:
      return 'w-6 h-6';
  }
});
</script>

<template>
  <svg
    class="icon"
    :class="sizeClasses"
    fill="currentColor"
    preserve-aspect-ratio="xMidYMid meet"
    aria-hidden="true"
    focusable="false"
  >
    <use :href="`${url}#icon-${kebabCase(props.iconName)}`" />
  </svg>
</template>
