<script setup lang="ts">
import { ref, type Ref } from 'vue';
import Popper from 'vue3-popper';

defineProps({
  placement: {
    type: String as () =>
      | 'auto'
      | 'auto-start'
      | 'auto-end'
      | 'top'
      | 'top-start'
      | 'top-end'
      | 'bottom'
      | 'bottom-start'
      | 'bottom-end'
      | 'right'
      | 'right-start'
      | 'right-end'
      | 'left'
      | 'left-start'
      | 'left-end'
      | undefined,
    default: 'auto'
  }
});

const popperOpen: Ref<boolean> = ref(false);

const togglePopper = () => (popperOpen.value = !popperOpen.value);
</script>

<template>
  <Popper :placement="placement" offset-distance="-4" @open:popper="togglePopper" @close:popper="togglePopper">
    <button class="flex items-center gap-2 h-navbar" type="button">
      <slot name="label" />
      <Icon
        icon-name="ChevronDown"
        class="transform rotate-0 transition duration-300 ease-in-out text-quartz"
        :class="{
          'transform !-rotate-180 ': popperOpen
        }"
      />
    </button>
    <template #content="{ close }">
      <slot name="content" :close-popper="close" />
    </template>
  </Popper>
</template>

<style>
:root {
  --popper-theme-background-color: theme('colors.background.DEFAULT');
  --popper-theme-background-color-hover: theme('colors.background.DEFAULT');
  --popper-theme-padding: 0;
  --popper-theme-box-shadow: theme('boxShadow.dropdown');
}
</style>

<style lang="scss">
.dropdown-list {
  @apply flex flex-col gap-4 p-4 text-left;
}

.dropdown-item {
  @apply cursor-pointer text-foreground;
}
</style>
