<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useLocaleStore } from '@/stores';

const { availableLocales } = useI18n();
const { setLocale } = useLocaleStore();

defineProps<{
  bgColor?: string;
}>();
</script>

<template>
  <Dropdown placement="bottom">
    <template #label>
      <span class="uppercase">{{ $i18n.locale }}</span>
    </template>
    <template #content="{ closePopper }">
      <ul class="dropdown-list min-w-[10rem]">
        <li
          v-for="locale in availableLocales"
          :key="locale"
          class="dropdown-item uppercase w-fit"
          @click="
            setLocale(locale);
            closePopper();
          "
        >
          {{ $t(`locales.${locale}`) }}
        </li>
      </ul>
    </template>
  </Dropdown>
</template>
