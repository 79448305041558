import { onMounted, onUnmounted, ref } from 'vue';

export const useWindowScroll = () => {
  const isHidden = ref(true);

  const handleScroll = (): void => {
    window.scrollY < window.innerHeight ? (isHidden.value = true) : (isHidden.value = false);
  };

  onMounted((): void => {
    document.addEventListener('scroll', handleScroll);
  });

  onUnmounted((): void => {
    document.removeEventListener('scroll', handleScroll);
  });

  return {
    isHidden
  };
};
