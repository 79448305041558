<script setup lang="ts">
import { app } from '@/constants';
import { reactive } from 'vue';

const props = defineProps<{
  small?: boolean;
  secondary?: boolean;
  buttonClass?: object | string;
}>();

const buttonType = reactive({
  'btn-small': props.small,
  'btn-primary': !props.secondary,
  'btn-secondary': props.secondary
});
</script>

<template>
  <a
    :href="`mailto:${app.contactEmail}?subject=${$t('shared.mailto.subject')}`"
    class="btn"
    :class="[buttonType, buttonClass]"
    target="_blank"
    rel="noreferrer"
  >
    <icon v-if="!small" icon-name="calendar" class="w-6 h-6"></icon>
    {{ $t(`shared.btnRdv.${small ? 'small' : 'default'}`) }}
  </a>
</template>
