import { createWebHistory, createRouter } from 'vue-router';
import type { RouteLocationNormalized, NavigationGuardNext, RouteRecordRaw } from 'vue-router';

import { routes } from '@/router';

export const router = createRouter({
  history: createWebHistory(),
  routes: routes as RouteRecordRaw[]
});

/* Navigation guard */
// TODO remove if app has no authentification or admin guards
router.beforeEach(
  (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const requireAdmin = to.meta.requireAdmin ?? false;
    const requireAuth = requireAdmin || (to.meta.requireAuth ?? false);
    // TODO adapt the origin of the login information
    const currentUser = { admin: false };

    // Both authentification & admin guards
    if (requireAuth && !currentUser) {
      next('/login');
    } else if (requireAdmin && !currentUser?.admin) {
      next('/login');
    } else if (to.name === 'login' && currentUser) {
      /* Prevent access to login route if user is already logged */
      next(from.fullPath);
    } else {
      next();
    }

    // Only authentification guard
    // if (requireAuth && !currentUser) {
    //   next('/login');
    // } else if (to.name === 'login' && currentUser) {
    //   /* Prevent access to login route if user is already logged */
    //   next(from.fullPath);
    // } else {
    //   next();
    // }
  }
);
