<script setup lang="ts">
import { useWindowScroll } from '@/hooks';
const { isHidden } = useWindowScroll();
</script>

<template>
  <Header :is-hidden="isHidden" />
  <div class="min-h-screen flex flex-col">
    <main>
      <RouterView />
    </main>
  </div>
  <ScrollTop :is-hidden="isHidden" />
  <Footer />
</template>
