import { createI18n } from 'vue-i18n';
import frTranslations from './fr-FR.json';
import enTranslations from './en-EN.json';

const messages = {
  fr: frTranslations,
  en: enTranslations
};

export const i18n = createI18n({
  globalInjection: true,
  legacy: false, // set to `false` to use Composition API
  locale: 'fr',
  fallbackLocale: 'fr',
  messages
});
