import type { RouteLocationNormalized, NavigationGuardNext, RouteRecordRaw } from 'vue-router';
import { i18n } from '@/assets/translations';

const translatedRoutes: RouteRecordRaw[] = i18n.global.availableLocales.flatMap(
  (locale: string) => [
    // {
    //   path: `/${i18n.global.t('navigation.home.url', null, { locale })}`,
    //   name: `${locale}-home`,
    //   component: () => import('@/components/pages/HomePage.vue')
    // },
  ]
);

const staticRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/components/pages/HomePage.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/systeme-de-design',
    name: 'design-system',
    component: () => import('@/components/pages/DesignSystemPage.vue'),
    meta: { requireAuth: false },
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => (import.meta.env.VITE_SENTRY_ENV === 'Développement' ? next() : next('/'))
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'page-not-found',
    component: () => import('@/components/pages/NotFoundPage.vue')
  }
];
export const routes: RouteRecordRaw[] = [...translatedRoutes, ...staticRoutes];
