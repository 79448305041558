<script lang="ts" setup>
defineProps<{
  isHidden: boolean;
}>();

const scrollToTop = (): void => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
};
</script>

<template>
  <button class="arrow-up" :class="{ hidden: isHidden }" @click="scrollToTop()">
    <Icon icon-name="ChevronUp" />
  </button>
</template>

<style scoped lang="scss">
.arrow-up {
  @apply fixed bottom-4 right-4 p-1 rounded-full bg-foreground text-background transition-transform ease-in-out duration-300 md:bottom-8 md:right-8;

  &:hover {
    @apply scale-110;
  }
}
</style>
