import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { createGtm } from '@gtm-support/vue-gtm';

import App from '@/App.vue';
import { router } from '@/router';
import { i18n } from '@/assets/translations';

import { useLocaleStore } from '@/stores';

import '@/assets/styles/scss/index.scss';

const app = createApp(App);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

app.use(pinia);
app.use(router);
app.use(i18n);

if (import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID) {
  app.use(
    createGtm({
      id: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID,
      enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
      debug: import.meta.env.VITE_GOOGLE_TAG_MANAGER_DEBUG_MODE, // => Dev only
      vueRouter: router
    })
  );
}
app.mount('#app');

i18n.global.locale.value = useLocaleStore().value;
